<template>
    <div class="screen-box" ref="ops">
        <div class="pos">
            <el-row>
                <el-col :span="8" class="pos-order">
                    <el-table :data="state.tableData" border>
                        <el-table-column prop="goods_name" label="商品名稱"></el-table-column>
                        <el-table-column prop="img_url" label="商品圖片">
                            <template #default="scope">
                                <el-image
                                        class="goodImg"
                                        :src="scope.row.img_url"
                                        :preview-src-list="[scope.row.img_url]"
                                        fit="cover"
                                ></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" label="數量" width="70"></el-table-column>
                        <!--<el-table-column prop="attr_values" label="規格" width="70"></el-table-column>-->
                        <el-table-column prop="price" label="金額" width="70"></el-table-column>
                        <el-table-column label="操作" width="100" fixed="right">
                            <template #default="scope">
                                <el-button type="text" size="small" @click="delSingleGoods(scope.row)">刪除</el-button>
                                <el-button type="text" size="small" @click="addOrderList(scope.row)">增加</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="foot-statistical">
                        <el-form
                                size="small"
                                :model="state.ruleForm"
                                :rules="state.rules"
                                :ref="(el) => (formRefs = el)"
                                class="demo-ruleForm"
                                label-width="100px"
                        >
                            <el-form-item label="會員id：" class prop="vipId">
                                <el-input v-model="state.ruleForm.vipId" placeholder="請輸入會員id" class="wd150" size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="訂單折扣：">
                                <el-input
                                        v-model="state.ruleForm.discount"
                                        @input="discountInput"
                                        placeholder="請輸入折扣"
                                        class="wd150"
                                        size="small"
                                ></el-input>
                                <span>
                  <small>數量：</small>
                  {{state.totalCount}}
                </span>
                                <span>
                  <small>金額：</small>
                  {{state.totalMoney}}元
                </span>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="foot-btn">
                        <el-button @click="delAllGoods">取消</el-button>
                        <el-button type="primary" @click="checkout('ruleForm')">確定</el-button>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="goods-type" v-if="state.Goodsdata.length>0">
                        <el-tabs @tab-click="handleClick">
                            <el-tab-pane :label="item.name" v-for="(item,index) in state.Goodsdata" :key="index">
                                <ul class="cookList">
                                    <li
                                            v-for="(goods,index) in item.goods"
                                            :key="index"
                                            @click="addOrderList(goods,goods)"
                                    >
                                        <!--@click="clickItem(item.goods[index].skus,item.goods[index])"-->
                                        <el-image class="goodImg" :src="goods.goods_img" fit="cover"></el-image>
                                        <div class="food-content">
                                            <p class="foodName line-two">{{goods.goods_name}}</p>
                                        </div>
                                        <div class="food-content1">
                                            <span class="foodPrice">${{goods.price}}元</span>
                                        </div>
                                    </li>
                                </ul>
                            </el-tab-pane>
                        </el-tabs>
                        <!--<div class="my-pagination">-->
                            <!--<paging-->
                                    <!--ref="paging"-->
                                    <!--@currentChange="(e)=>{$_handleCurrentChange({item:e})}"-->
                                    <!--@sizeChange="(e)=>{$_handleSizeChange({item:e})}"-->
                            <!--&gt;</paging>-->
                        <!--</div>-->
                        <div class="my-pagination">
                            <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="state.user.page"
                                    :page-sizes="[10, 20, 30, 40, 50]"
                                    :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="Number(state.count)"
                            >
                            </el-pagination>
                        </div>
                    </div>
                    <div v-else class="flex_c_m">暫無數據</div>
                </el-col>
            </el-row>
        </div>
        <!-- 商品規格彈框 -->
        <!--<Edit ref="edit" @addOrderList="addOrderList"></Edit>-->
    </div>
</template>

<script>
    import { reactive, getCurrentInstance, ref, defineComponent } from "vue";
    export default defineComponent({
        name: "OfflineOrders",
        setup() {
            const state = reactive({
                tableData: [], // 表格数据
                user: {
                    // 请求参数
                    limit: 10,
                    page: 1
                },
                Goodsdata: [],
                totalCount: 0,
                totalMoney: 0,
                ruleForm: {
                    discount: "", //折扣
                    vipId: "", //會員id
                },
                tabIndex: "", //tabs切換的索引
                rules: {
                    //表单验证
                    vipId: [{ required: true, message: "請輸入會員id", trigger: "blur" }],
                },
            });

            const formRefs = ref(null);

            const proxy = getCurrentInstance().appContext.config.globalProperties;
            const { $http, $message, $loading } = proxy;

            const init = (par = {}) =>{
                $http.getProductInfo(state.user).then((res) => {
                    console.log(res);
                    if (res.status !== 200) return;
                    // state.count = res.data.count;
                    state.Goodsdata = res.data;
                    state.Goodsdata.forEach((e, i) => {
                        if (i == state.tabIndex) {
                            state.count = e.goods_count;
                        }
                    });
                });
            };

            init();

            const handleCurrentChange = (val) => {
                state.user.page = val;
                init();
            };

            // 分頁組件改變每頁顯示条数
            const handleSizeChange = (val) => {
                state.user.limit = val;
                init();
            };

            // 删除单个商品
            const delSingleGoods = (goods) => {
                state.tableData = state.tableData.filter(
                    (o) => o.id != goods.id
                );
                getAllMoney();
            };

            // 因为删除，增加后都要重新汇总，所以汇总方法要复用
            const getAllMoney = () => {
                state.totalCount = 0;
                state.totalMoney = 0;
                state.ruleForm.discount = "";
                state.ruleForm.vipId = "";
                // 订单列表有数据的时候才进行汇总
                if (state.tableData) {
                    // 进行数量和价格的汇总计算
                    // element表示tableData中的单个元素
                    state.tableData.forEach((element) => {
                        state.totalCount += element.number;
                        if (state.ruleForm.discount!=='') {
                            let money = state.totalMoney + element.price * element.number;
                            state.totalMoney = (money * state.ruleForm.discount) / 10;
                        } else {
                            state.totalMoney = state.totalMoney + element.price * element.number;
                        }
                    });
                }
            };
            //輸入折扣改變就計算價格
            const discountInput = () => {
                if (state.totalCount != 0) {
                    state.totalMoney = (state.ruleForm.discount * state.totalMoney) / 10;
                    if(state.ruleForm.discount==''){
                        state.tableData.forEach((e,i)=>{
                            state.totalMoney = state.totalMoney + e.price * e.number;
                        })
                    }
                } else {
                    $message.error("請先添加商品！！！");
                    state.ruleForm.discount = "";
                }
            };
            // 点击商品，添加到左边
            const addOrderList = (skus, goods) => {
                // 每次添加都清零，防止重复添加
                // this.totalCount = 0;
                // this.totalMoney = 0;
                // 定义isHave判断这个商品是否已经存在于订单列表
                let isHave = false;
                // tableData循环
                for (let i = 0; i < state.tableData.length; i++) {
                    if (state.tableData[i].id == skus.id) {
                        isHave = true;
                    }
                }
                // 根据判断的值写业务逻辑
                if (isHave) {
                    // 改变列表中的商品数量
                    // 通过filter过滤tableData
                    let arr = state.tableData.filter((o) => o.id == skus.id);
                    if (arr[0].number >= skus.goods_stock) {
                        $message({
                            message: `此商品庫存最多添加${skus.goods_stock}個`,
                            type: "warning",
                        });
                        return;
                    }
                    arr[0].number++;
                } else {
                    // 构造一个newGoods
                    let newGoods = {
                        id: skus.id,
                        goods_name: goods.goods_name,
                        price: skus.price,
                        // attr_values: skus.attr_values,
                        img_url: skus.goods_img,
                        number: 1,
                        stock: skus.goods_stock,
                        // specs: skus.id,
                    };
                    state.tableData.push(newGoods);
                }
                getAllMoney();
            };
            const handleClick = (event) => {
                state.tabIndex = event.index;
                init();
            };
            // 删除所有商品
            const delAllGoods = () => {
                state.tableData = [];
                state.totalCount = 0;
                state.totalMoney = 0;
                state.ruleForm.discount = "";
                state.ruleForm.vipId = "";
            };
            // 结账
            const checkout = (formName) => {
                if (state.totalCount != 0) {
                    formRefs.value.validate((valid) => {
                        if (valid) {
                            let data = {
                                money: state.totalMoney,
                                discount: state.ruleForm.discount,
                                goods: state.tableData,
                                user_id: state.ruleForm.vipId,
                            };
                            // console.log(data)
                            // return
                            $http.addOfflineOrder(data).then((res) => {
                                $message({
                                    message: "操作成功",
                                    type: "success",
                                });
                                state.tableData = [];
                                state.ruleForm.discount = "";
                                state.totalMoney = 0;
                                state.totalCount = 0;
                                state.ruleForm.vipId = "";
                            });
                        }
                    });
                } else {
                    $message.error("請先添加商品！！！");
                }
            };
            return {
                state,
                handleCurrentChange,
                handleSizeChange,
                delSingleGoods,
                discountInput,
                addOrderList,
                handleClick,
                delAllGoods,
                checkout,
                formRefs
            };

        },
        // methods: {
        //     //獲取分類商品
        //

        //     //點擊商品規格彈框
        //     clickItem(row, goods) {
        //         this.$refs.edit.init(row, goods);
        //     },






    })
</script>

<style lang="scss" scoped>
    .screen-box {
        background: #f3f3f4;
        height: 100%;
    }
    .demo-form-inline {
        background: #fff;
        height: 100%;
        padding: 20px 20px 20px 20px;
    }
    .dialog-footer {
        margin-left: 120px;
    }
    .pos .el-row,
    .pos .el-col {
        height: 100%;
    }
    .pos-order {
        padding: 10px;
        background: #fbfcff;
        border-right: 1px solid #e7e7e7;
    }
    .goods-type {
        padding: 10px;
    }
    .foot-statistical {
        padding: 10px;
        background: #fff;
        border: 1px solid #e7e7e7;
        border-top: none;
    }
    .foot-statistical span {
        margin: 0 10px;
        font-size: 16px;
    }
    .foot-statistical span small {
        font-size: 12px;
    }
    .foot-btn {
        padding: 10px;
        text-align: center;
    }
    .title {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        border-bottom: 1px solid #d3dce6;
        background-color: #f9fafc;
        padding: 10px;
    }
    .o-price {
        color: #58b7ff;
    }
    .cookList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    // align-items: center;
        justify-content: flex-start;
    }
    .cookList li {
        list-style: none;
        width: 120px;
        border: 1px solid #e5e9f2;
        height: 120px;
        background-color: #fff;
        padding: 2px;
        display: flex;
        flex-direction: column;
        margin: 2px;
        cursor: pointer;
    }
    .goodImg {
        width: 50px;
        height: 50px;
    }
    .food-content {
        flex: 1;
    }
    .food-content span {
        width: 100%;
    }
    .foodName {
        font-size: 16px;
        padding-left: 10px;
        color: brown;
        margin: 0;
    }
    .foodPrice {
        font-size: 16px;
        padding-left: 10px;
        padding-top: 10px;
    }
    .qr-code {
        text-align: center;
    }
    .flex_c_m {
        width: 100%;
        height: 500px;
    }
</style>
